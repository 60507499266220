<template>
  <div>

      <div class="order-info" v-if="orderDetail">
        <div class="order-info-box">
          <div class="info">
            <div class="control">
              <div class="label">台位</div>
              <div class="value">
                {{ orderDetail.tableName }}
              </div>
            </div>

            <div class="control">
              <div class="label">时间</div>
              <div class="value">
                {{ $filters.formatDate(orderDetail.bookTime, "MM-DD HH:mm") }}
              </div>
            </div>

            <div class="control">
              <div class="label">人数</div>
              <div class="value">{{ orderDetail.peopleCount }}</div>
            </div>

            <div class="control">
              <div class="label">订单金额</div>
              <div class="value">¥ <strong style="color:#f50">{{ orderDetail.orderAmount }}</strong></div>
            </div>

            <div class="control">
              <div class="label">菜品金额</div>
              <div class="value">¥ <strong style="color:#f50">{{ orderDetail.caijinAmount }}</strong></div>
            </div>
          </div>

          <div class="items">

            <div class="item" v-for="(v, k) in orderItems2" :key="k">
              <div>
                <a-tag color="green" v-if="v.state === 0">加菜</a-tag>
                <a-tag color="orange" v-if="v.state === 1">赠菜</a-tag>
                {{ v.dish.name }}
                <span class="count">
                  <CloseOutlined style="font-size: 10px" />
                  {{ v.quantity }}
                </span>
              </div>
              <div class="item-right">
                ¥<span>{{ v.sellingPrice }}</span>
              </div>
            </div>

            <div class="item" v-for="(v, k) in orderItems0" :key="k">
              <div>
                <a-tag color="blue">套餐</a-tag>
                {{ v.dish.name }}
                <span class="count">
                  <CloseOutlined style="font-size: 10px" />
                  {{ v.quantity }}
                </span>
              </div>
              <div class="item-right">
                ¥<span>{{ v.sellingPrice }}</span>
              </div>
            </div>

            <div class="item" v-for="(v, k) in orderItems3" :key="k">
              <div>
                <a-tag color="blue">酒水</a-tag>
                {{ v.dish.name }}
                <span class="count">
                  <CloseOutlined style="font-size: 10px" />
                  {{ v.quantity }}
                </span>
              </div>
              <div class="item-right">
                ¥<span>{{ v.sellingPrice }}</span>
              </div>
            </div>

            <div class="item" v-for="(v, k) in orderItems4" :key="k">
              <div>
                <a-tag color="red">损耗</a-tag>
                {{ v.dish.name }}
                <span class="count">
                  <CloseOutlined style="font-size: 10px" />
                  {{ v.quantity }}
                </span>
              </div>
              <div class="item-right">
                ¥<span>{{ v.sellingPrice }}</span>
              </div>
            </div>

            <div class="item" v-for="(v, k) in orderItems5" :key="k">
              <div>
                <a-tag color="red" v-if="v.targetOrderId === orderId">{{
                  v.fromTableName
                }}</a-tag>
                <a-tag color="red" v-else>{{ v.toTableName }}</a-tag>
                {{ v.dish.name }}
                <span class="count">
                  <CloseOutlined style="font-size: 10px" />
                  {{ v.quantity }}
                </span>
              </div>
              <div class="item-right">
                <div v-if="v.targetOrderId !== orderId">
                  ¥<span>{{ v.sellingPrice }}</span>
                </div>
                <div v-else>¥0</div>
              </div>
            </div>

            <div class="item" v-for="(v, k) in orderItems" :key="k">
              <div>
                {{ v.dish.name }}
                <span class="count">
                  <CloseOutlined style="font-size: 10px" />
                  {{ v.quantity }}
                </span>
              </div>
              <div class="item-right">
                ¥<span>{{ v.sellingPrice }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { PullRefresh } from 'vant'
import 'vant/es/pull-refresh/style'
import { CloseOutlined } from '@ant-design/icons-vue'
import {} from 'ant-design-vue'
import form from '@/mixins/form'
import { OrderClass } from '@/apis/order'
import storage from 'store'

const order = new OrderClass()
export default defineComponent({
  components: {
    [PullRefresh.name]: PullRefresh,
    CloseOutlined
  },
  mixins: [form],
  setup () {
    const spinning = ref(false)
    const refreshing = ref(false)
    const orderDetail = ref()
    const orderItems = ref([])
    const orderItems0 = ref([])
    const orderItems2 = ref([])
    const orderItems3 = ref([])
    const orderItems4 = ref([])
    const orderItems5 = ref([])
    const orderId = ref('')

    const onGetOrder = () => {
      spinning.value = true
      const tableId = storage.get('SCAN_TABLE_ID')
      order.scanDetail(tableId).then((resp) => {
        orderDetail.value = resp
        orderId.value = resp.orderId

        // 必点品
        orderItems.value = resp.orderItems.filter((x) => x.dish.isMust)
        // 加菜或赠菜
        orderItems2.value = resp.orderItems.filter((x) => x.type === 4)
        // 套餐菜品
        orderItems0.value = resp.orderItems.filter((x) => x.type === 0)
        // 酒水
        orderItems3.value = resp.orderItems.filter((x) => x.type === 2)
        // 损耗
        orderItems4.value = resp.orderItems.filter((x) => x.type === 3)
        orderItems5.value = resp.orderItems.filter((x) => x.type === 5)

        spinning.value = false
        refreshing.value = false
      })
    }

    const onRefresh = () => {
      refreshing.value = true
      onGetOrder()
    }

    onGetOrder()
    return {
      spinning,
      refreshing,
      orderDetail,
      orderItems,
      orderItems2,
      orderItems3,
      orderItems4,
      orderItems5,
      orderItems0,
      orderId,
      onGetOrder,
      onRefresh
    }
  },

  mounted () {},

  methods: {}
})
</script>

<style lang="less" scoped>
.order-info {
  width: 100%;
  height: 100vh;

  .order-info-box {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }

  .info {
    background: #d3eaff;
    padding: 0.2rem;

    .control {
      display: flex;
      justify-content: space-between;
      height: 30px;
      align-items: center;
      .label {
        color: #999;
      }
      .value {
        color: #333;
        font-weight: 600;
      }
    }
  }

  .item {
    padding: 5px;
    font-size: 0.24rem;
    height: 0.8rem;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #666;

    .count {
      font-size: 0.2rem;
    }

    .item-right {
      font-size: 0.24rem;
      color: #f50;
      span {
        font-size: 0.3rem;
        padding-left: 2px;
      }
    }

    &.title {
      background: #f8f8f8;
      border-bottom: 3px solid #ebebeb;
      font-size: 0.28rem;
      color: #333;
      font-weight: 600;
      padding: 5px 10px;
    }
  }

  .items {
    padding: 0.2rem;
  }
}
</style>
